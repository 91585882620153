<template>
  <div class="detail-discuss-container">
    <div class="content-container">
      <div
        class="new-discuss"
        @click="
          showDiscuss = true;
          showBtn = false;
        "
        v-if="showBtn"
      >
        发起新的讨论
      </div>
      <div class="discuss-box" v-show="showDiscuss">
        <div class="nav">
          <span>发起讨论</span>
          <img
            :src="require('@/assets/imgs/scale.png')"
            alt=""
            @click="
              showDiscuss = false;
              showBtn = true;
            "
          />
        </div>
        <div class="item title">
          <Input placeholder="请输入讨论标题" v-model="title"></Input>
          <input type="text" style="width: 0; opacity: 0;border: none;" />
        </div>
        <div class="item content">
          <Input
            type="textarea"
            placeholder="请输入内容"
            v-model="content"
          ></Input>
        </div>
        <div class="item btn">
          <Button
            type="primary"
            shape="circle"
            @click="handleSubmit"
            v-throttleClick="2000"
            >发布</Button
          >
        </div>
      </div>
      <Every
        v-for="(item, i) in everyList"
        :key="i"
        :every="item"
        style="margin-top: 0; margin-bottom: 40px"
      ></Every>
      <p v-show="isLoding" style="text-align: center; color: rgb(105, 98, 98)">
        加载中....
      </p>
    </div>
    <div class="side">
      <div class="cooperation">
        <h2 class="title">热门讨论</h2>
        <ul class="box">
          <li v-for="(v, i) in sideList" :key="i" :class="{ active: i === 0 }">
            <div class="name" @click="goDiscuss(v)">{{ v.title }}</div>
            <div class="content">
              {{ v.replyNum }}个回答 · {{ v.createDate | dayFormate }} ·
              {{ v.createUserName }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Every from "./Every.vue";
import { mapState } from "vuex";
export default {
  components: {
    Every,
  },
  computed: {
    ...mapState(["hasLogin", "userInfo"]),
  },
  created() {
    this.circleIndexRecommendPage({
      pageNum: 1,
      pageSize: 2,
      circleId: this.$route.query.id,
    });
    this.hotDiscussList({
      id: this.$route.query.id,
    });
    if (this.$route.query.title) {
      this.showDiscuss = true;
      this.showBtn = false;
      this.title = this.$route.query.title;
      this.content = this.$route.query.content;
    }
  },
  data() {
    return {
      showDiscuss: false,
      showBtn: true,
      sideList: [],
      everyList: [],
      pageNum: 1, // 当前页数
      total: 0, // 总条数
      content: "",
      title: "",
      isLoding: false, // 加载loding
    };
  },
  methods: {
    goDiscuss(e) {
      let url = this.$router.resolve({
        path: "discussdetails",
        query: {
          id: e.id,
        },
      });
      window.open(url.href, "_blank");
    },
    // 监听滚动条
    handleScroll() {
      const homeDoc = document.getElementsByClassName("home-container");
      if (
        document.documentElement.scrollTop + document.body.offsetHeight ==
        homeDoc[0].offsetHeight
      ) {
        if (this.total / 2 > this.pageNum && !this.isLoding) {
          this.circleIndexRecommendPage({
            pageNum: this.pageNum + 1,
            pageSize: 2,
            circleId: this.$route.query.id,
          });
        }
      }
    },
    async handleSubmit() {
      if (!this.hasLogin) {
        this.$store.commit("showAlert", true);
        return
      }
      if (this.userInfo.user.customerType !== 2) {
        this.$Message.error("当前是未认证的账号，请先去认证");
        return;
      }
      if (!this.hasLogin) {
        this.$Message.error("请登录");
        return;
      }
      if (this.title === "") {
        this.$Message.error("标题不能为空");
        return;
      }
      if (this.content === "") {
        this.$Message.error("内容不能为空");
        return;
      }
      if (this.title.length > 100) {
        this.$Message.error("字数已达上限");
        return;
      }
      // 修改讨论
      if (this.$route.query.title) {
        const resp = await this.$apis.circleServe.updateDiscuss({
          content: this.content,
          title: this.title,
          circleId: this.$route.query.id,
          id: this.$route.query.myId,
        });
        if (resp.data.resultCode === "200") {
          this.$Message.success(resp.data.resultDesc);
        } else if(resp.data.resultCode !== "1009") {
          this.$Message.error(res.data.resultDesc);
        }
      } else {
        const resp = await this.$apis.circleServe.addDiscuss({
          content: this.content,
          title: this.title,
          circleId: this.$route.query.id,
        });
        if (resp.data.resultCode === "200") {
          this.$Message.success(resp.data.resultDesc);
        } else if(resp.data.resultCode !== "1009") {
          this.$Message.error(res.data.resultDesc);
        }
      }
      this.content = "";
      this.title = "";
    },
    async circleIndexRecommendPage(data) {
      this.isLoding = true;
      const resp = await this.$apis.circleServe.circleIndexRecommendPage(data);
      if (resp.data) {
        this.everyList.push(...resp.data.list);
        this.pageNum = +resp.data.pageNum;
        this.total = +resp.data.total;
      }
      this.isLoding = false;
      window.addEventListener("scroll", this.handleScroll); // 给滚动条添加事件
    },
    async hotDiscussList(data) {
      const resp = await this.$apis.circleServe.hotDiscussList(data);
      this.sideList = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-discuss-container {
  display: flex;
  .new-discuss {
    width: 1040px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #00a4ff;
    border-radius: 19px;
    font-size: 18px;
    color: #00a4ff;
    text-align: center;
    line-height: 38px;
    cursor: pointer;
    margin: 20px 0;
  }
  .discuss-box {
    width: 1040px;
    min-height: 348px;
    border: 1px dotted #999999;
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 30px;
    border-radius: 19px;
    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      span {
        font-size: 24px;
        color: #00a4ff;
      }
      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }
    .item {
      margin-bottom: 20px;
    }
    .title {
      ::v-deep .ivu-input {
        height: 40px;
      }
    }
    .content {
      ::v-deep .ivu-input {
        height: 100px;
      }
    }
    .btn {
      text-align: right;
      ::v-deep .ivu-btn {
        height: 38px;
        width: 120px;
      }
    }
  }
}
.side {
  width: 360px;
  margin: -60px 0 40px 40px;
  .cooperation {
    width: 360px;
    margin-bottom: 30px;
    background: #ffffff;
    border: 1px dotted #999999;
    border-radius: 6px;
    position: relative;
    padding: 30px;
    overflow: hidden;
    &::after {
      position: absolute;
      left: -1px;
      top: -1px;
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      background-image: url("~@/assets/imgs/squre.png");
      background-size: 100% 100%;
    }
    .box {
      li {
        margin: 30px 0;
        &.active {
          .name {
            color: #00a4ff;
          }
        }
        .name {
          font-size: 18px;
          cursor: pointer;
          color: #333333;
          overflow: hidden;
          display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示 。
          text-overflow: ellipsis; // 可以用来多行文本的情况下，用省略号“...”隐藏超出范围的文本 。
          -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式 。
          -webkit-line-clamp: 2;
        }
        .content {
          font-size: 14px;
          color: #999999;
        }
      }
    }
    .title {
      font-size: 22px;
      font-weight: 400;
      color: #00a4ff;
      margin-bottom: 25px;
    }
  }
}
</style>
